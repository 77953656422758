import getConfigs from "@/config/base";
import { Menu } from "@/types/menu";

const menuData: Menu[] = [
  {
    id: 2,
    title: "Solutions",
    newTab: true,
    submenu: [
      {
        id: 2.1,
        title: "Returns",
        path: "/returns",
        newTab: false,
      },
      {
        id: 2.2,
        title: "Liquidation",
        path: "/liquidation",
        newTab: false,
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "Pricing",
  //   path: "/pricing",
  //   newTab: false,
  // },
  {
    id: 4,
    title: "Nonprofit",
    newTab: false,
    path: "/nonprofit",
  },
  {
    id: 5,
    title: "Resources",
    newTab: true,
    submenu: [
      {
        id: 5.1,
        title: "About Us",
        path: "/about",
        newTab: false,
      },
      {
        id: 5.2,
        title: "FAQ",
        url: "https://help.liquidonate.com/",
        newTab: false,
      },
      {
        id: 5.3,
        title: "Blog",
        path: "/blog",
        newTab: false,
      },
    ],
  },
  {
    id: 6,
    title: "Shop",
    url: new URL("/shop", getConfigs().appSiteURL).href,
    newTab: false,
  },
  {
    id: 7,
    title: "Donate",
    url: new URL("/donate", getConfigs().appSiteURL).href,
    newTab: false,
  },
];
export default menuData;
