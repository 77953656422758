const getConfigs = () => {
  const env = process.env.NEXT_PUBLIC_ENV || "development";
  return {
    localdev: {
      siteURL: "http://localhost:3000",
      appSiteURL: "http://localhost:3001",
      apiURL: "http://localhost:8000",
      enableAjentic: false,
    },
    development: {
      siteURL: "http://localhost:3000",
      appSiteURL: "https://app-stage.liquidonate.com",
      apiURL: "https://api-stage.liquidonate.com",
      enableAjentic: false,
    },
    stage: {
      siteURL: "https://stage.liquidonate.com",
      appSiteURL: "https://app-stage.liquidonate.com",
      apiURL: "https://api-stage.liquidonate.com",
      enableAjentic: false,
    },
    sandbox: {
      siteURL: "https://sandbox.liquidonate.com",
      appSiteURL: "https://app-sandbox.liquidonate.com",
      apiURL: "https://api-sandbox.liquidonate.com",
      enableAjentic: false,
    },
    production: {
      siteURL: "https://liquidonate.com",
      appSiteURL: "https://app.liquidonate.com",
      apiURL: "https://api.liquidonate.com",
      enableAjentic: false,
    },
  }[env];
};

export default getConfigs;
