export const footerData = [
  {
    id: 1,
    title: "company",
    items: [
      { name: "About", link: "about" },
      { name: "Blog", href: "https://www.blog.liquidonate.com/" },
      { name: "Demo", link: "demo" },
      {
        name: "Careers",
        href: "https://jobs.gusto.com/boards/liquidonate-inc-6984f107-b115-40e9-8c73-432c4902cff4",
      },
    ],
  },
  {
    id: 2,
    title: "our policies",
    items: [
      { name: "FAQ", href: "https://help.liquidonate.com" },
      { name: "Privacy Policy", link: "privacy-policy" },
      { name: "Terms and Conditions", link: "terms-and-conditions" },
      { name: "Terms of Service", link: "terms-of-service" },
    ],
  },
  {
    id: 3,
    title: "contact us",
    contact: {
      address: (
        <span>
          LiquiDonate Inc. <br />
          149 New Montgomery Street,
          <br />
          Floor 4,
          <br />
          San Francisco, CA 94105.
        </span>
      ),
      email: "support@liquidonate.com",
    },
  },
];
