"use client";
// log specific events happening.

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    gtag: any;
  }
}

export const gtagEvent = ({
  action,
  category,
  label,
  value,
  send_to,
}: {
  action: string;
  category?: string;
  label?: string;
  value?: any;
  send_to?: string;
}) => {
  if (typeof window.gtag !== "undefined") {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
      send_to: send_to,
    });
  }
};
