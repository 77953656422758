"use client";

import { footerData } from "@/content/footerData";
import {
  email,
  facebook,
  instagram,
  linkedin,
  twitter,
  youtube,
} from "@/designs/svgIcons";
import useGraphQlQuery from "@/utility/useGraphQlQuery";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useRef, useState } from "react";

const Footer = () => {
  const pathname = usePathname();
  const formRef = useRef(null);
  const [formEmail, setFormEmail] = useState("");
  const { subscribe } = useGraphQlQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    grecaptcha.ready(function () {
      grecaptcha
        .execute(`${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`, {
          action: "submit",
        })
        .then(async function (token) {
          // Attach generated token to your API requests and validate it on the server
          const form = formRef.current;
          await subscribe({
            email: formEmail,
            token: token,
            reset: form.reset(),
          });
        });
    });
  };

  return (
    <>
      <footer
        className="wow fadeInUp relative z-10 overflow-hidden bg-[#F9F9F9]  "
        data-wow-delay=".1s"
      >
        <div className="container mx-auto ">
          <div className="separator-h "></div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div className=" py-8">
              <h2 className="pb-3 text-lg font-bold text-black">
                KNOW IT ALL FIRST!
              </h2>
              <p className="text-sm">
                Never miss anything from LiquiDonate by signing up to our
                newsletter.
              </p>
            </div>
            <div className="flex items-center justify-end">
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="flex w-full flex-row justify-end gap-x-4"
              >
                <input
                  type="email"
                  name="email"
                  placeholder="Enter you email"
                  className="outline-non input input-bordered w-full max-w-xs border-black bg-white focus:outline-none"
                  value={formEmail}
                  onChange={(e) => setFormEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="ease-in-up font-lg rounded-lg bg-primary px-8 py-3 font-bold uppercase text-white transition duration-300  hover:bg-primary/80  hover:bg-none  hover:shadow-signUp"
                >
                  {" "}
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          <div className="border-t py-10 ">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 md:w-1/2 lg:w-5/12 ">
                <div className="mb-12 max-w-[360px] lg:mb-16">
                  <Link
                    href="/"
                    className="mb-8 inline-block"
                  >
                    <div className="flex  items-end justify-center gap-x-2">
                      <Image
                        src="/images/logo/logo.svg"
                        alt="logo"
                        width={200}
                        height={10}
                      />
                    </div>
                  </Link>

                  <div className="flex items-center gap-x-4">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/liquidonate"
                      aria-label="social-link"
                      className="mr-6 text-primary/60 hover:text-primary"
                    >
                      {facebook}
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/liquidonate"
                      aria-label="social-link"
                      className="mr-6 text-primary/60 hover:text-primary"
                    >
                      {twitter}
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/liquidonate"
                      aria-label="social-link"
                      className="mr-6 text-primary/60 hover:text-primary"
                    >
                      {instagram}
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.youtube.com/@LiquiDonate"
                      aria-label="social-link"
                      className="mr-6 text-primary/60 hover:text-primary"
                    >
                      {youtube}
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/liquidonate"
                      aria-label="social-link"
                      className="mr-6 text-primary/60 hover:text-primary"
                    >
                      {linkedin}
                    </a>
                  </div>
                </div>
              </div>
              <div className="grid w-full grid-cols-1 gap-y-6 px-4 sm:w-1/2 md:w-1/2 lg:w-7/12 lg:grid-cols-3">
                {footerData.map((element) => (
                  <div key={element.id}>
                    <h2 className="mb-2 text-lg font-bold uppercase text-black ">
                      {element.title}
                    </h2>
                    <ul className="text-dark">
                      {element.items &&
                        element.items.map((item, indexing) => (
                          <li key={indexing}>
                            {item.link ? (
                              <Link
                                href={`/${item.link}`}
                                className={`${
                                  pathname === "/" + item.link &&
                                  "text-primary underline"
                                } mb-2 inline-block text-base font-medium underline-offset-4 hover:text-primary hover:underline`}
                              >
                                {item.name}
                              </Link>
                            ) : (
                              <a
                                href={item.href}
                                target="__blank"
                                className={` mb-2 inline-block text-base font-medium underline-offset-4 hover:text-primary hover:underline`}
                              >
                                {item.name}
                              </a>
                            )}
                          </li>
                        ))}
                      {element.contact && (
                        <>
                          <li>
                            <p
                              className={` mb-2 inline-block text-base font-medium underline-offset-4 `}
                            >
                              {element.contact.address}
                            </p>
                          </li>
                          <li>
                            <a
                              href={`mailto: ${element.contact.email}`}
                              className={` mb-2 flex items-center gap-x-2 text-base font-medium underline-offset-4 hover:text-primary hover:underline`}
                            >
                              <span>{email}</span>
                              {element.contact.email}
                            </a>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-[#FFFFFF] py-6">
          <p className="container mx-auto text-base text-black">
            © 2024 by {"   "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.liquidonate.com/"
            >
              LiquiDonate Inc.
            </a>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
